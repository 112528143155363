export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any =
    [{ "text": "General Nursing", "value": "1000" },
    { "text": "Entry Level", "value": "1001" },
    { "text": "RN Degree Programs", "value": "1002" },
    { "text": "RN to BSN Bridge Programs", "value": "1003" },
    { "text": "MSN Programs", "value": "1004" }
    ]

