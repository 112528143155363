import FormIndex from "../components/LeadFormSteps/FormIndex";
import NursingListing from "../Container";

export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: NursingListing
    },
    {
        name: "General Nursing",
        path: "/nursing/generalnursing",
        exact: true,
        component: NursingListing
    },
    {
        name: "Entry Level",
        path: "/nursing/entrylevel",
        exact: true,
        component: NursingListing
    },
    {
        name: "RN Degree Programs",
        path: "/nursing/rndegreeprograms",
        exact: true,
        component: NursingListing
    },
    {
        name: "RN to BSN Bridge Programs",
        path: "/nursing/rntobsnbridgeprograms",
        exact: true,
        component: NursingListing
    },
    {
        name: "MSN Programs",
        path: "/nursing/msnprograms",
        exact: true,
        component: NursingListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: NursingListing
    }
]
